import React from 'react'

export const AboutPage = (): React.JSX.Element => {
    return (
        <div className={'flex flex-col lg:items-start'}>
            <img src={'/assets/despre_title.svg'} alt={'Contact page'} className={'h-[230px] pb-24 hidden lg:block'} />
            <p className={'font-light text-md lg:text-2xl lg:w-3/4 leading-9 text-justify'}>Grupul Ap.6 este o colectivă de tineri artiști absolvenți care și-au unit forțele pentru a explora și celebra nostalgia anilor 2000 prin arta vizuală. Membrii grupului, Radu Mihai-Tănase, Ioana Mihai, Pansy (Alexandra Tîrpescu), Marean (Marian Stavarache), Laura Stoica, Ruxandra Mărgineanu și Cristiana Ursache, aduc în prim-plan amintirile și influențele lor pentru a crea o experiență artistică captivantă.</p>
        </div>
    )
}
