import React from 'react'
import { Footer } from '../../components'
import { LandingPageLogo, LandingPageMenu } from './components'

export const LandingPage = (): React.JSX.Element => {
    return (
        <div className={'w-screen h-screen overflow-x-auto bg-cover bg-primary flex flex-col lg:bg-[url("http://34.89.236.83/assets/landing_page_cover_min.jpg")] lg:bg-cover lg:bg-center justify-between gap-8'} >
            <div className={'w-full lg:h-full flex flex-col lg:justify-center lg:items-center'}>
                <div style={{ backgroundImage: 'url("/assets/landing_page_cover_min.jpg")' }} className={'w-full h-[480px] bg-center lg:hidden lg:z-0 flex justify-center items-center'}>
                    <img className={'shadow-md w-full lg:hidden'} src={'/assets/landing_page_cover_min.jpg'} alt={''} />
                </div>
                <div className={'w-full flex flex-col gap-4 lg:gap-6'}>
                    <LandingPageLogo />
                    <LandingPageMenu />
                </div>
            </div>
            <Footer className={'lg:bg-transparent'} />
        </div>
    )
}
