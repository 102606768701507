import React from 'react'
import { NavLink } from 'react-router-dom'

export const MenuItemList = ({ onItemClicked }: { onItemClicked?: () => void }): React.JSX.Element => {
    return (
        <>
            <NavLink onClick={onItemClicked} to={'/projects'} className={({ isActive }) => isActive ? 'font-bold' : '' }><li className={'uppercase select-none cursor-pointer hover:font-bold'}>proiecte</li></NavLink>
            <NavLink onClick={onItemClicked} to={'/members'} className={({ isActive }) => isActive ? 'font-bold' : '' }><li className={'uppercase select-none cursor-pointer hover:font-bold'}>membri</li></NavLink>
            <NavLink onClick={onItemClicked} to={'/contact'} className={({ isActive }) => isActive ? 'font-bold' : '' }><li className={'uppercase select-none cursor-pointer hover:font-bold'}>contact</li></NavLink>
            <NavLink onClick={onItemClicked} to={'/about'} className={({ isActive }) => isActive ? 'font-bold' : '' }><li className={'uppercase select-none cursor-pointer hover:font-bold'}>despre</li></NavLink>
        </>
    )
}
