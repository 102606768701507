import React from 'react'
import { DesktopMenu } from './DesktopMenu'
import { MobileMenu } from './MobileMenu'
import { Footer } from './Footer'

export const WebsiteLayout = ({
    children,
    title
}: { children: React.JSX.Element, title?: string }): React.JSX.Element => {
    return (
        <>
            <div className={'w-full h-full flex flex-row'}>
                <DesktopMenu/>
                <div className={'w-full flex flex-col'}>
                    <div className={'lg:hidden w-full min-h-[72px] max-h-[72px] bg-light block'}>
                        <MobileMenu title={title}/>
                    </div>
                    <div className={'block 2xl:pl-64 xl:pl-32 lg:px-16 lg:ml-[373px] lg:pt-[108px] py-8 px-4'}>
                        {children}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}
