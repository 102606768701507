import React from 'react'
import { memberPreviews } from '../types'
import { Link } from 'react-router-dom'

export const MemberInfoMenu = ({ currentMemberId }: { currentMemberId: string }): React.JSX.Element => {
    return (
        <div className={'w-full grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-6 lg:p-4 lg:border lg:border-primary place-items-center'}>
            {
                memberPreviews.filter(member => member.id !== currentMemberId).map((member, idx) => (
                    <Link reloadDocument={true} key={idx} to={`/members/${member.id}`} className={'w-[150px] h-[150px] lg:w-auto lg:h-auto relative'}>
                        <img src={member.imageBig} className={'cursor-pointer w-full h-full object-cover'} alt={''} />
                        <span className={'lg:hidden text-center uppercase px-8 absolute bottom-0 bg-secondary font-light text-white w-full'}>{member.firstName}</span>
                    </Link>
                ))
            }
        </div>
    )
}
