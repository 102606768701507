import React from 'react'
import classNames from 'classnames'

export const TextButton = ({ className, text, onClick }: { className?: string, text: string, onClick?: () => void }): React.JSX.Element => {
    return (
        <div onClick={onClick} className={classNames('flex flex-col items-center justify-center border border-primary cursor-pointer hover:bg-green p-1', className ?? '')}>
            <span className={'uppercase text-md'}>{text}</span>
        </div>
    )
}
