export interface Contact {
    name: string
    email: string
}

export interface ContactsGroup {
    main: Contact
    members: Contact[]
}

export const contactList: ContactsGroup = {
    main: {
        email: 'ap6.contact@gmail.com',
        name: 'Email'
    },
    members: [
        {
            name: 'Cristiana Ursache',
            email: 'cristiana.ursache@gmail.com'
        },
        {
            name: 'Ruxandra Mărgineanu',
            email: 'margineanu.ruxandra99@gmail.com'
        },
        {
            name: 'Ioana Mihai',
            email: 'Ioanaeartista@gmail.com'
        },
        {
            name: 'Laura Stoica',
            email: 'laura.stoica.student@gmail.com'
        },
        {
            name: 'Radu - Mihai Tănasă',
            email: 'raduthe@yahoo.com'
        },
        {
            name: 'Marian Stavarache',
            email: 'stavarache.marian1@gmail.com'
        },
        {
            name: 'Alexandra Tîrpescu',
            email: 'pansydoesstuff@gmail.com'
        }
    ]
}
