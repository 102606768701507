import React from 'react'
import { MembersPreview } from '../components/MembersPreview'
import { memberPreviews } from '../types'

export const MembersPage = (): React.JSX.Element => {
    return (
        <div className={'flex flex-row gap-4 w-full'}>
            <img src={'/assets/members_title.svg'} alt={''} className={'h-full  pt-16 hidden lg:block'} />
            <div className={'flex flex-col w-full items-center lg:items-start gap-8'}>
                <div className={'grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 grid-cols-2 gap-8 lg:gap-4 w-full lg:p-4'}>
                    {
                        memberPreviews.map((preview, idx) => (
                            <MembersPreview key={idx} memberPreview={preview} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
