export interface Project {
    id: string
    name: string
    date: string
    description: string
    coverImage: string
    menuImage: string
    nextId: string
    prevId: string
}

export const emptyProject: Project = {
    nextId: '',
    prevId: '',
    coverImage: '',
    date: '',
    description: '',
    id: '',
    menuImage: '',
    name: ''
}

export const projects: Project[] = [
    {
        id: '441.2_lei',
        name: '441,2 lei',
        coverImage: '/assets/441_cover.jpg',
        menuImage: '/assets/441_cover_menu.jpg',
        date: '11.08.2023',
        nextId: 'balkan.y2k',
        prevId: 'compromis',
        description: 'În ediția anterioară, intitulată „400 RON”, am fost inspirați nu doar de povestea artiștilor disidenți/ subversivi din perioada comunistă, ci și de apartamentul comunist în sine. Impregnat permanent în memoria colectivă, apartamentul comunist suferă schimbări continue și majore în România secolului XXI, e devenind o fosilă vie e transformându-se în ceva cu totul nou, aproape de nerecunoscut. Mutând privirea spre noul mileniu, vedem cum locuința construită de Partid se transformă din bun de uz personal în asset comercial. Generațiile (post-) nouăzeciste care au reușit să își cumpere apartamente proprii se găsesc acum cu moșteniri precum apartamentele de tip hrusciovka, ale căror aspect aduce aminte prea puternic despre ororile unei ere demult apuse. Într-un climat cultural și economic care promovează dezvoltarea, progresul și intrarea în pas cu Vestul, vechile apartamente construite în jurul anilor ’70 devin adevărate simboluri ale unor traume pe care mulți ar prefera să o uite. Singurii care mai suferă sunt e nostalgicii, e nepoții, al căror contact cu comunismul s-a făcut doar prin povești și analogii vizuale.'
    },
    {
        id: 'balkan.y2k',
        name: 'Balkan Y2K',
        coverImage: '/assets/balkan_y2k_cover.jpg',
        menuImage: '/assets/balkan_y2k_menu.jpg',
        date: '31.11.2023',
        nextId: '400.lei',
        prevId: '441.2_lei',
        description: 'Pe măsură ce observăm succesul care este tendința de Y2K Revival care se răspândește peste tot în lume, este important să ne uităm înapoi la acea epocă și să ne întrebăm: ai cui anii 2000 îi reînviĂm cu adevărat? Pentru mulți oameni din afara Europei de Vest și a SUA, Y2K-urile erau foarte diferite de imaginile care circulă astăzi. Chiar și astăzi, se pare că suntem întotdeauna cu 10 ani în spatele culturii curente din SUA.'
    },
    {
        id: '400.lei',
        name: '400 lei',
        coverImage: '/assets/400_lei_cover.gif',
        menuImage: '/assets/400_lei_menu.png',
        date: '21.08.2022',
        nextId: 'estul.salbatic',
        prevId: 'balkan.y2k',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
        id: 'estul.salbatic',
        name: 'ESTUL SĂLBATIC',
        coverImage: '/assets/estul_salbatic_cover.jpg',
        menuImage: '/assets/estul_salbatic_menu.jpg',
        date: '31.11.2023',
        nextId: 'compromis',
        prevId: '400.lei',
        description: 'Am ajuns să realizăm această expoziție-manifest în urma frustrărilor nenumărate pe care am început să le resimțim de la primul an de licență până în prezent. Proiectul 400 lei a fost rezultatul încercărilor repetate de a folosi aceste frustrări într-un mod atât constructiv, cât și avantajos pentru grupul nostru artistic. Vrem ca această publicație să constituie un mesaj pentru scena artistică din Iași; o dedicație din partea unor studenți care au avut ghinionul de a nu-și găsi clienți sau donatori imediat de pe băncile facultății.'
    },
    {
        id: 'compromis',
        name: 'COMPROMIS',
        coverImage: '/assets/compromis_cover.jpg',
        menuImage: '/assets/compromis_menu.png',
        date: '31.11.2023',
        nextId: '441.2_lei',
        prevId: 'estul.salbatic',
        description: 'Schimbând unghiul standard de vedere al individului șablonat și aducând o orientare intrinsecă individuală, se ridică adesea problematizări diverse privind conflicte interioare ample. Ele pot fi de natură socială, politică, istorică și nu numai, având ca determinant o puternică amprentă integrantă personală. Filtrul particular, consolidat de back-ground-ul unic al fiecărei persoane este generatorul de compromisuri care trebuie făcute pentru a te ajusta sistematic standardului, asemenea modelului unui pat a lui Procust. Indignarea adresată acestui gen de sistematizare a umanității este cea care a adus împreună pe cei cinci expozanți ce invoca propriile experiențe materializate la nivel vizual, exprimând ce a însemnat pentru ei compromisul în multimplele ipostaze ale sale.'
    }
]

export const projectById = (projectId: string): Project => {
    const project = projects.find((project, idx) => {
        return project.id === projectId
    })

    if (project === undefined) {
        return emptyProject
    }

    return project
}
