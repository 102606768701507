import { type Project } from '../types'
import React from 'react'
import { Separator, TextButton } from '../../../components'
import { Link } from 'react-router-dom'

export const Project441LeiContents = ({ project }: { project: Project }): React.JSX.Element => {
    return (
        <div className={'flex flex-col gap-8 p-4 lg:max-w-[80%]'}>
            <div className={'flex flex-col-reverse lg:flex-row justify-start gap-4 lg:justify-between'}>
                <div className={'flex flex-col border-b border-primary py-2 w-full lg:w-1/4 cursor-pointer'}>
                    <p className={'font-medium text-primary lg:text-3xl xl:text-5xl text-4xl uppercase'}>{project.name}</p>
                    <p className={'font-normal text-grey uppercase'}>{project.date}</p>
                </div>
                <div className={'flex flex-row justify-center items-center w-full lg:w-1/2'}>
                    <Link to={'https://drive.google.com/file/d/16BVkiCaMZ2LdSQ3GcgDovJ8JVO0zOuuw/view'} target={'_blank'}>
                        <TextButton className={'px-8 py-2'} text={'PUBLICAȚIE'} />
                    </Link>
                    <Separator className={'bg-primary w-full'} />
                </div>
            </div>

            <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                În ediția anterioară, intitulată „400 RON”, am fost inspirați nu doar de povestea artiștilor disidenți/ subversivi din perioada comunistă, ci și de apartamentul comunist în sine. Impregnat permanent în memoria colectivă, apartamentul comunist suferă schimbări continue și majore în România secolului XXI, e devenind o fosilă vie e transformându-se în ceva cu totul nou, aproape de nerecunoscut. Mutând privirea spre noul mileniu, vedem cum locuința construită de Partid se transformă din bun de uz personal în asset comercial. Generațiile (post-) nouăzeciste care au reușit să își cumpere apartamente proprii se găsesc acum cu moșteniri precum apartamentele de tip hrusciovka, ale căror aspect aduce aminte prea puternic despre ororile unei ere demult apuse. Într-un climat cultural și economic care promovează dezvoltarea, progresul și intrarea în pas cu Vestul, vechile apartamente construite în jurul anilor ’70 devin adevărate simboluri ale unor traume pe care mulți ar prefera să o uite. Singurii care mai suferă sunt e nostalgicii, e nepoții, al căror contact cu comunismul s-a făcut doar prin povești și analogii vizuale.
            </p>

            <img src={'/assets/441_01.jpg'} className={'w-[320px] h-[160px] object-center -ml-8 lg:w-full lg:h-full lg:m-0'} />

            <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                Însă pentru cei care înțeleg regulile noului sistem, această moștenire poate deveni și o resursă nanciară. Acesta este și cazul pentru Apartament 6 și noul spațiu de expunere. Dacă unul din substraturile ediției „400 RON” a fost întoarcerea în timp și îmbrățișarea nostalgiei urbane ale anilor 90 și 2000, în „441,2” focusul se îndreaptă din ce în ce mai mult spre prezent, iar nostalgia devine subiectul central de discuție. Mobilierul fabricat aproape în totalitate în cooperativele locale este acum înlocuit de mobilierul „Tineret”, nume simbolic menit să sublinieze noua schimbare de sistem și de mentalitate. Televizorul cu tub catodic este înlocuit permanent odată la câțiva ani cu noi modele de televizoare cu ecran plat, ecare cu o diagonală mai mare decât precedenta. În bibliotecă și pe frigidere își fac simțită prezența țările din afara blocului comunist e sub formă de cărți, suveniruri sau alte obiecte găsite prin vacanțe, excursii și teambuildinguri. Toate aceste detalii formează împreună imaginea unei familii tipice clasei de mijloc undeva între anii 2000 și 2010.
            </p>

            <div className={'grid grid-cols-2 border-t border-t-primary'}>
                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary lg:py-8 lg:pr-8 flex flex-col gap-8'}>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light border-r border-r-primary pr-4 pt-4 lg:border-none'}>
                        Dacă în ediția trecută, trecerea de la vechiul la noul sistem era menționată în contextul discursului narativ și a metodologiei de bază a Apartamentului 6, acum ea devine realitate. Practica artistică de a realiza expoziții de apartament nu mai este acum delimitată de un singur reper geograc, ea devine un proces nomad în cadrul căruia nu doar lucrările în sine trebuie luate în considerare, ci și spațiul și istoria sa personală. În cazul de față, spațiul actual subliniază mai puternic absența copilăriei și tranziția completă spre maturitate, cu atât mai mult cu cât în acest an am încheiat de asemenea cu toții studiile de master. Între timp, vechiul apartament al bunicilor a fost distrus și refăcut de la zero, ind dat acum în chirie. Rămâne astăzi doar o amintire.
                    </p>

                    <img src={'/assets/441_02.jpg'} className={'justify-end lg:hidden'} />

                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Pentru a încheia, am dori să menționăm că noul titlu de „441,2 RON” a fost dat în urma unui calcul cu privire la rata inației rezultată în urma începerii războiului în Ucraina. La nivel actual, dacă am  dorit să expunem acest proiect într-o galerie UAP plătind o taxă similară, cei 400 de lei de acum unu doi ani s-ar traduce astăzi în aproximativ 441,2 RON. Ne propunem să continuăm această tradiție în următoarele ediții 400 RON, pentru a evidenția importanța promovării artiștilor emer - genți pe zona locală și pentru a demonstra cum condițiile actuale nu sunt îndeajuns de satisfăcătoare pentru creatorii independenți.
                    </p>
                </div>

                <div className={'col-span-full lg:col-span-1 hidden lg:p-8 lg:flex flex-col justify-center items-center'}>
                    <img src={'/assets/441_02.jpg'} className={'w-full'} />
                </div>

                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Ruxandra</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Mărgineanu</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/441_03.jpg'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>Am considerat că nu există variantă mai bună de a surprinde esența expunerii într-un apartament decât realizarea unei lucrări ce îl are ca element de bază, deși de nepătruns. Din acest motiv, scara unui bloc comunist devine subiectul imaginii, un spațiu familiar, care, probabil, evocă sentimente diferite în funcție de generație - poate pentru bunicii mei inspiră securitate, mamei mele i-ar sugera un neajuns, iar mie nostalgie.</p>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>Deoarece optez aproape mereu pentru formatul pătrat, am ales să realizez această formă din mai multe lucrări 1:1, care diferă prin tonalitatea cromatică. Astfel, nu numai că spațiul banal, imaginea aceasta arhicunoscută devine individuală, dar îi sunt și atribuite diverse sentimente în funcție de culoarea predominantă. Imaginile exterioare sunt mult mai saturate în acest sens, deși aleg să nu atribui vreo emoție oricărei dintre ele, în timp ce în mijloc se află cea mai apropiată de realitatea mea, în tonuri calde, în concordanță cu sentimentele proprii față de acest tip de imagine.</p>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}> Deși, în general, probabil că blocurile din pereți prefabricați nu oferă o imagine tocmai „colorată”, consider că este momentul ca arta să depășească aceste viziuni previzibile - în cazul blocurilor, al locuințelor (o temă, de altfel, extrem de ofertantă), mai ales, interpretările pot fi mult mai nuanțate decât griul comunismului în care au fost construite.</p>
                        <div className={'w-full flex flex-col gap-2 p-4 lg:hidden'}>
                            <img src={'/assets/441_04.jpg'} className={'w-full'} alt={'AP 6 - RUXANDRA MARGINEANU'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Ap 6</span> - Ruxandra Mărgineanu</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <img src={'/assets/441_03.jpg'} className={'w-full'} />
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/441_04.jpg'} className={'w-full'} alt={'AP 6 - RUXANDRA MARGINEANU'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Ap 6</span> - Ruxandra Mărgineanu</p>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 lg:border-r lg:border-r-primary'}>
                    <img src={'/assets/441_05.jpg'} className={'w-full'} />
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light pr-8'}>Deși e o filmare veridică spre deosebire de scena din jocul de pe calculator, ambele cadre sunt, în teorie, imagini virtuale, o imitație a realului. Memoria afectivă nu ține cont de realitatea concretă, ci își creează propriul său univers imaginar - întocmai cum și eu mai demult îmi imaginam un tramvai care m-ar fi dus din CUG direct în Păcurari.</p>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light pr-8'}>Lucrarea de față este o încercare de continuare a narativei creată în proiectul meu de disertație ”What if you embraced your inner stereotype?”/ „Ce s-ar întâmpla dacă ai îmbrățișa stereotipul din tine?” - povești despre ce înseamnă să fii emigrant român.</p>
                </div>

                <div className={'col-span-full lg:col-span-1  pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-start justify-start text-end pl-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Radu-Mihai</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Tănasă</p>
                    </div>
                    <div className={'lg:py-8 lg:pl-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/441_05.jpg'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>Titlul „Tramvaiul din Păcurari în CUG” face referire la cele două cartiere în care mi-am petrecut majoritatea vieții; în unul trăiau bunicii mei, în altul părinții mei. Până la momentul actual, între aceste două cartiere nu a existat niciodată un mijloc de transport în comun care să meargă dintr-un capăt al unui cartier înspre celălalt. - singura legătură directă dintre ele era cea personal-afectivă, a mea.</p>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>Conținutul lucrării de video art e compus din cadre deformate ale apartamentului bunicilor din arhiva personală, suprapuse peste o scenă dintr-un joc video al cărui environment virtual este o replică a cartierului în care locuiam cu părinții mei. Contrastul dintre cadrele de arhivă și cele virtuale reflectă afectul memoriei personale: apartamentul bunicilor, așa cum îl știam eu, nu mai există - doar cadrele filmate mai aduc aminte de ultimele sale momente de viață.</p>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light lg:hidden'}>Deși e o filmare veridică spre deosebire de scena din jocul de pe calculator, ambele cadre sunt, în teorie, imagini virtuale, o imitație a realului. Memoria afectivă nu ține cont de realitatea concretă, ci își creează propriul său univers imaginar - întocmai cum și eu mai demult îmi imaginam un tramvai care m-ar fi dus din CUG direct în Păcurari.</p>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light lg:hidden'}>Lucrarea de față este o încercare de continuare a narativei creată în proiectul meu de disertație ”What if you embraced your inner stereotype?”/ „Ce s-ar întâmpla dacă ai îmbrățișa stereotipul din tine?” - povești despre ce înseamnă să fii emigrant român.</p>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                            <img src={'/assets/441_06.jpg'} className={'w-full'} alt={'Tramvaiul din Păcurari în CUG - Radu-Mihai Tănasă'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Tramvaiul din Păcurari în CUG</span> - Radu-Mihai Tănasă</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>MAREAN</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/441_07.jpg'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>Lucrăriele funcționează ca o relatare a unor vremuri mult mai rele, dar care prin prisma ignoranței mele infantile păreau mult mai bune. Ca să fiu consistent acum ar trebui sa fac o referință la cum acele vremuri trăite în apartamentul părinților mei au jucat un rol pivotant în arta mea, dar am crescut la casă…, așa că în cadrul lucrărilor folosesc cele mai similare repere spațiale de care am dispus pentru a substituii “apartamentul”. Una dintre lucrări surprinde un moment marcant în istoria României și anume aderarea la UE în 2007, în istoria mea acest moment a fost marcat de tata care mi-a schimbat canalul ca sa se uite la artificiile de pe TVR1.</p>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>Per total 2007 nu a fost cel mai bun an din viață mea pentru că am realizat ca sunt sărac. A doua lucrare este o reproducere fidelă a cea ce unii ar numi “Child Labor”, dar tata le-ar numi activități tată-fiu și relatează cum cum am pus într-o după amiaza în jur de 50 de șpalieri de beton.</p>
                        <div className={'w-full flex flex-col gap-2 p-4 lg:hidden'}>
                            <img src={'/assets/441_08.jpg'} className={'w-full'} alt={'Untitled - Marian Stavarache'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Untitled</span> - Marian Stavarache</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-4 lg:hidden'}>
                            <img src={'/assets/441_09.png'} className={'w-full'} alt={'Chimp dream 2007 - Marian Stavarache'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Chimp dream 2007 </span> - Marian Stavarache</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <img src={'/assets/441_07.jpg'} className={'w-full'} />
                    <div className={'flex flex-row'}>
                        <div className={'w-1/2 flex flex-col gap-2 p-0 lg:p-4'}>
                            <img src={'/assets/441_08.jpg'} className={'w-full'} alt={'Untitled - Marian Stavarache'} />
                            <p className={'w-full uppercase text-lg'}><span className={'font-bold'}>Untitled</span> - Marian Stavarache</p>
                        </div>
                        <div className={'w-1/2 flex flex-col gap-2 p-0 lg:p-4'}>
                            <img src={'/assets/441_09.png'} className={'w-full'} alt={'Chimp dream 2007 - Marian Stavarache'} />
                            <p className={'w-full uppercase text-lg'}><span className={'font-bold'}>Chimp dream 2007 </span> - Marian Stavarache</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 lg:border-r lg:border-r-primary'}>
                    <img src={'/assets/441_10.jpg'} className={'w-full'} />
                </div>

                <div className={'col-span-full lg:col-span-1  pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-start justify-start text-end pl-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Ioana</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Mihai</p>
                    </div>
                    <div className={'lg:py-8 lg:pl-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/441_10.jpg'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>Am încercat să surprind absurdul situației economice al locuințelor într-un performance realizat în baia apartamentului expoziției. Practica pieței postliberale privează consumatorii de condiții cotidiene decente prin impunerea unui schimb nerezonabil. Performance-ul a presupus un efort imaginativ de a-mi însuși spațiul: încerc să mă adaptez și să îl adaptez la obiceiurile personale. Documentarea arată cum schimbul este departe de a avea un ton serios, fiind mai degrabă vorba de o reflexie jovială a contextului.</p>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                            <img src={'/assets/441_11.jpg'} className={'w-full'} alt={'VÂNDUT. Proprietar închiriez garsonieră modernă, 11mp, 200 euro (ușor negociabil)  - Radu-Mihai Tănasă'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>VÂNDUT. Proprietar închiriez garsonieră modernă, 11mp, 200 euro (ușor negociabil) </span> - Ioana Mihai</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>PANSY</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/441_12.jpg'} className={'w-full -ml-8 lg:hidden'} />
                        <div className={'w-full flex flex-col gap-2 p-4'}>
                            <img src={'/assets/441_13.jpg'} className={'w-full'} alt={'Untitled - Alexandra Tirpescu'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Untitled</span> - PANSY</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <img src={'/assets/441_12.jpg'} className={'object-center w-[23rem]'} />
                </div>

                <div className={'col-span-1 hidden lg:flex border-b border-r border-r-primary border-b-primary p-20'}>
                </div>
                <div className={'col-span-1 hidden lg:flex border-b border-b-primary p-20'}>
                </div>
            </div>
        </div>
    )
}
