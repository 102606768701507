import React from 'react'
import { Link } from 'react-router-dom'
import { MenuItemList } from './MenuItemList'

export const DesktopMenu = ({ theme = 'dark' }: { theme?: string }): React.JSX.Element => {
    return (
        <div className={`hidden fixed lg:flex flex-col w-[373px] z-10 left-0 top-[106px] border p-8 gap-8 ${theme === 'dark' ? 'border-primary' : 'border-light'}`}>
            <div className={'w-full flex flex-col justify-center items-end'}>
                <Link to={'/'}>
                    <img src={`${theme === 'dark' ? '/assets/logo_dark.svg' : '/assets/logo_light.svg'}`} alt={''} className={'pt-[36px]'} />
                </Link>
            </div>

            <nav className={'w-full flex flex-col justify-center items-end'}>
                <ul className={`text-end text-primary text-lg flex flex-col gap-4 pb-[80px] ${theme === 'dark' ? 'text-primary' : 'text-white'}`}>
                    <MenuItemList />
                </ul>
            </nav>
        </div>
    )
}
