import React from 'react'
import { type Project } from '../types'
import { Link } from 'react-router-dom'
import { Separator, TextButton } from '../../../components'

export const Project400LeiContents = ({ project }: { project: Project }): React.JSX.Element => {
    return (
        <div className={'flex flex-col gap-8 p-4 lg:max-w-[80%]'}>
            <div className={'flex flex-col-reverse lg:flex-row justify-start gap-4 lg:justify-between'}>
                <div className={'flex flex-col border-b border-primary py-2 w-full lg:w-1/4 cursor-pointer'}>
                    <p className={'font-medium text-primary lg:text-3xl xl:text-5xl text-4xl uppercase'}>{project.name}</p>
                    <p className={'font-normal text-grey uppercase'}>{project.date}</p>
                </div>
                <div className={'flex flex-row justify-center items-center w-full lg:w-1/2'}>
                    <Link to={'https://drive.google.com/file/d/1-LMAhD1ZjA_O9avNRTP9v2eZDmdhYkdH/view'} target={'_blank'}>
                        <TextButton className={'px-8 py-2'} text={'PUBLICAȚIE'} />
                    </Link>
                    <Separator className={'bg-primary w-full'} />
                </div>
            </div>

            <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                În ediția anterioară, intitulată „400 RON”, am fost inspirați nu doar de povestea artiștilor disidenți/ subversivi din perioada comunistă, ci și de apartamentul comunist în sine. Impregnat permanent în memoria colectivă, apartamentul comunist suferă schimbări continue și majore în România secolului XXI, e devenind o fosilă vie e transformându-se în ceva cu totul nou, aproape de nerecunoscut. Mutând privirea spre noul mileniu, vedem cum locuința construită de Partid se transformă din bun de uz personal în asset comercial. Generațiile (post-) nouăzeciste care au reușit să își cumpere apartamente proprii se găsesc acum cu moșteniri precum apartamentele de tip hrusciovka, ale căror aspect aduce aminte prea puternic despre ororile unei ere demult apuse. Într-un climat cultural și economic care promovează dezvoltarea, progresul și intrarea în pas cu Vestul, vechile apartamente construite în jurul anilor ’70 devin adevărate simboluri ale unor traume pe care mulți ar prefera să o uite. Singurii care mai suferă sunt e nostalgicii, e nepoții, al căror contact cu comunismul s-a făcut doar prin povești și analogii vizuale.
            </p>

            <p className={'text-3xl lg:text-5xl uppercase font-normal pb-8 border-b border-b-primary w-full lg:w-3/4'}>motive</p>

            <div className={'flex flex-col gap-16 lg:flex-row lg:p-4 p-0 text-lg w-full lg:w-3/4'}>
                <ol className={'text-justify font-light lg:w-1/3 w-full space-y-6'}>
                    <li className={'list-none'}>I. Lipsa unor spații REALE de expunere pt studenți (ApArte nu se pune)</li>
                    <ol className={'list-decimal ml-4 space-y-2'}>
                        <li>Galeriile private nu ne-ar accepta pt ca nu suntem o investiție bună</li>
                        <li>Galeriile publice/de stat:</li>
                        <ol className={'list-decimal ml-4'}>
                            <li>Ori sunt mereu rezervate non-stop de UAP-iști</li>
                            <li>Ori cer o chirie al cărui preț nu este acoperit de facultate</li>
                        </ol>
                    </ol>
                </ol>
                <ol className={'text-justify font-light lg:w-1/3 w-full space-y-6'}>
                    <li className={'list-none'}>II. Lipsa unor posibilități de expunere accesibile pentru studenți 1. </li>
                    <ol className={'list-decimal ml-4 space-y-2'}>
                        <li>Problema de preț (la galeriile publice)</li>
                        <li>Lipsa unui număr mare de galerii</li>
                        <li>Dimensiunile galeriilor sunt dificil de utilizat (prea mari: Victoria; prea mici: Tonitza)</li>
                        <li>Lipsa de echipament și infrastructură pentru lucrări de instalație/ foto-video/ new-media șamd</li>
                    </ol>
                </ol>
                <ol className={'text-justify font-light lg:w-1/3 w-full space-y-6'}>
                    <li className={'list-none'}>III. Lipsa unor spații REALE de expunere pt studenți (ApArte nu se pune)</li>
                    <ol className={'list-decimal ml-4 space-y-2'}>
                        <li>Galeriile private nu ne-ar accepta pt ca nu suntem o investiție bună</li>
                        <li>Galeriile publice/de stat:</li>
                        <ol className={'list-decimal ml-4'}>
                            <li>Ori sunt mereu rezervate non-stop de UAP-iști</li>
                            <li>Ori cer o chirie al cărui preț nu este acoperit de facultate</li>
                        </ol>
                    </ol>
                </ol>
            </div>

            <div className={'grid grid-cols-2 border-t border-t-primary'}>
                <div className={'col-span-full lg:col-span-1 border-r border-r-primary pr-4 lg:py-8 lg:pr-8 flex flex-col gap-8 pb-8'}>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        400 lei a fost, în termeni simpli și direcți, o expoziție făcută la cineva acasă.
                    </p>

                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Nu este și nu pretindem că ar fi o idee revoluționară sau nemaiîntâlnită. În termeni puțin mai complicați, 400 lei este o reinterpretare a practicii des întâlnite în perioada ceaușistă, și anume cea a realizării unui vernisaj privat într-un apartament personal. La acea vreme, singura modalitate prin care artiștii puteau să se exprime liber, fără a fi cenzurați sau mai rău, arestați sau dați pe mâna Securității, era de a organiza evenimente artistice private, unde în general participau doar prieteni și apropiați.
                    </p>

                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        O abordare destul de populară, practicată în special de Dan Perjovschi, era de a organiza vernisaje în propriul său apartament, unde artiștii aveau libertate de exprimare absolută, și puteau, măcar pe moment, să uite de restricțiile impuse de partid și de conducător. România de astăzi nu mai are un dictator, chiar dacă unii oameni ar putea argumenta contrariul; iar astăzi nu mai există teama cenzurii, a închisorii sau a lagărului. În schimb, problemele de natură economică sau financiară sunt la ordinea zilei: cu cât ai mai mulți bani, cu atât ești mai liber.
                    </p>

                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Astăzi galeriile de artă nu au un cod de reguli și nici interdicții după care trebuie să te supui pentru a expune. Atât timp cât plătești o chirie și ai pilele corecte, poți prezenta ORICE dorești. Undeva aici se situează și proiectul 400 lei.
                    </p>

                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Nu am realizat această expoziție din teama de a fi cenzurați sau băgați la pârnaie; ci din lipsă financiară. Sigur, o taxă de 400 RON (preț standard pre-inflație conflict Ucraina) pentru a expune într-o galerie nu e mult pe moment, dar este un prag pe care mulți studenți nu îl pot atinge în speranța dezvoltării unei cariere artistice. Mai ales dacă vrei să expui mai des decât odată pe an.
                    </p>

                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Prin intermediul acestui protest/ happening, am vrut să exersăm o alternativă constructivă la problema spațiilor de expunere accesibile pentru studenții ieșeni.
                    </p>

                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Nu pretindem că ar fi modalitatea perfectă sau ideală de promovare sau de construire a unei cariere artistice de calitate, dar considerăm că este un bun start.
                    </p>
                </div>

                <div className={'w-full -mr-24 col-span-full lg:hidden'}>
                    <img src={'/assets/400_01.png'} className={'w-full lg:hidden '} />
                </div>

                <div className={'col-span-full lg:col-span-1 hidden lg:p-8 lg:flex flex-col justify-center items-center'}>
                    <img src={'/assets/400_01.png'} className={'w-full'} />
                </div>

                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Ioana</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Mihai</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/400_02.jpg'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>Performance-ul Galeria Mobilă propune transformarea spațiului expozițional static într-un happening care inversează logica conceptului de white cube: nu vizitatorii sunt cei care intră în galerie, ci artiștii sunt cei care prezintă arta lor publicului. Abordarea este în strânsă legătură și cu tema de disertație a Ioanei Mihai, care vorbește despre statutul artiștilor emergenți. Performance-ul are o notă critică sugerată atât de gestul în sine, cât și de locația aleasă (Muzeul Național de Artă). Numărul redus al oportunităților profesionale și a promovării artiștilor la început de drum reprezintă o adevărată problemă. Această metodologie pare că sprijină mai degrabă contextul favorabil și nu performanța. Critica instituțională nu este adresată în mod special Muzeului Național de Artă, ci a fost aleasă această clădire pentru a reprezenta un simbol al artei instituționalizate.</p>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4 lg:hidden'}>
                            <img src={'/assets/400_04.png'} className={'w-full'} alt={'GALERIA MOBILĂ - IOANA mIHAI'} />
                            <p className={'w-full uppercase text-md'}><span className={'font-bold'}>GALERIA MOBILĂ</span> - IOANA mIHAI</p>
                            <p className={'w-full uppercase text-sm'}>performers: Ioana Mihai, Radu Carp, Alexandra Pintea</p>
                        </div>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light lg:hidden'}>
                            Atât s-a putut este o lucrare care însumează eforturile artiștilor emergenți de a se susține prin propria artă. Neavând parte de ajutor instituțional, artiștii emergenți trebuie să se preocupe atât de producția artistică, de promovare, de documentare cât și de problemele cotidiene. Astfel, timpul pentru producție scade semnificativ.
                        </p>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                            <img src={'/assets/400_03.png'} className={'w-full'} alt={'ATÂT S-A PUTUT - Ioana Mihai'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>ATÂT S-A PUTUT</span> - Ioana Mihai</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <img src={'/assets/400_02.jpg'} className={'w-full'} />
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/400_04.png'} className={'w-full'} alt={'GALERIA MOBILĂ - IOANA mIHAI'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>GALERIA MOBILĂ</span> - IOANA mIHAI</p>
                        <p className={'w-full uppercase text-sm'}>performers: Ioana Mihai, Radu Carp, Alexandra Pintea</p>
                    </div>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light pl-8'}>
                        Atât s-a putut este o lucrare care însumează eforturile artiștilor emergenți de a se susține prin propria artă. Neavând parte de ajutor instituțional, artiștii emergenți trebuie să se preocupe atât de producția artistică, de promovare, de documentare cât și de problemele cotidiene. Astfel, timpul pentru producție scade semnificativ.
                    </p>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 lg:border-r lg:border-r-primary'}>
                    <img src={'/assets/400_05.jpg'} className={'w-full'} />
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light pr-8'}>
                        Lucrarea Vernisaj la Galeria de Artă Contemporană a fost un performance realizat (i)legal în cadrul șantierului de construcție a clădirii (ce avea să fie) intitulată Centrul Internațional de Artă Contemporană Iași. Arhiva video a fost apoi transformată în acest video. Un personaj aproape uman realizează o expoziție aproape normală în cadrul unei galerii de artă aproape finisate. Își asumă aproape toate rolurile unui personal implicat într-un vernisaj, de la artist în sine, la curator, femeie de serviciu și chiar public.
                    </p>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light pr-8'}>
                        De ce am trecut eu și Alex prin toate astea? În primul rând voiam să fim primii oameni care fac o lucrare de artă și o „expoziție” la Centrul INTERNAȚIONAL de Artă. În al doilea rând, am vrut să aducem atenția cu privire la statulul artei contemporane în orașul Iași. Al doilea cel mai mare oraș din România are o infrastructură culturală contemporană aproape inexistentă. Suntem bătuți de orașe precum Cluj, și ajunși din urmă de Galați.
                    </p>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light pr-8'}>
                        Considerăm că acest lucru merită schimbat. Acum un an, în februarie, clădirea se presupunea a fi gata. Administrația s-a făcut că plouă, iar noi, la rândul nostru, ne-am prefăcut că expunem și prestăm servicii culturale.
                    </p>
                </div>

                <div className={'col-span-full lg:col-span-1  pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-start justify-start text-end pl-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Radu-Mihai</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Tănasă</p>
                    </div>
                    <div className={'lg:py-8 lg:pl-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/400_05.jpg'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                            O copie aproape fidelă după ambalajul unui joc „made în China„ pentru copii găsit la o tonetă din Podu Roș. Utilizarea pe scară largă, fără niciun fel de autorizație, a unor diferite entități copyrighted (Pokemon, WhatsApp, Mickey Mouse etc) este o practică vizuală des întâlnită în țări din lumea a Doua și a Treia; și un semn clar al dezvoltării și îmbrățișării pe scară largă a industriei capitaliste. Scoasă în afara contextului său comercial, imaginea amintește într-o oarecare măsură de o reinterpretare „de prost gust” a lucrării „Cina cea de Taină”, de da Vinci.
                        </p>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                            <img src={'/assets/400_06.jpg'} className={'w-full'} alt={'Untitled (Pokemon & Teletubbies) - Radu-Mihai Tănasă'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Untitled (Pokemon & Teletubbies)</span> - Radu-Mihai Tănasă</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                            <img src={'/assets/400_07.gif'} className={'w-full'} alt={'Vernisaj la Galeria de Artă Contemporană Radu - Radu-Mihai Tănasă'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Vernisaj la Galeria de Artă Contemporană Radu</span> - Radu-Mihai Tănasă</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>MAREAN</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/441_07.jpg'} className={'w-full -ml-8 lg:hidden'} />

                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light lg:hidden'}>
                            Lucrarea La galerie la Vericu` a fost realizată din disprețul personal pentru conținutul artistic ce apare frecvent în spațiile expoziționale din Iași, care este de cele mai multe ori repetitiv, învechit și în general expus doar ca să fie expus.
                        </p>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light lg:hidden'}>
                            Ca și structură lucrarea este foarte simplu asamblată, fundalul este relizat într-o tentativă la tehnica pouring replicată conform unor tutoriale de pe YouTube, peste acesta este pozitionat Vericu`.
                        </p>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light lg:hidden'}>
                            Vericu este o reprezentare a unei entități colective, arhetipul individului care suprasaturează scena artistică actuală, de acea am folosit ca și referință formatul de memă boomer wojack.
                        </p>

                        <div className={'w-full flex flex-col gap-2 p-4'}>
                            <img src={'/assets/400_09.jpg'} className={'w-full'} alt={'La galerie la Vericu\' - Marian Stavarache'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>La galerie la Vericu&lsquo;</span> - Marian Stavarache</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <img src={'/assets/400_08.jpg'} className={'w-full'} />
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light pl-8'}>
                        Lucrarea La galerie la Vericu` a fost realizată din disprețul personal pentru conținutul artistic ce apare frecvent în spațiile expoziționale din Iași, care este de cele mai multe ori repetitiv, învechit și în general expus doar ca să fie expus.
                    </p>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light pl-8'}>
                        Ca și structură lucrarea este foarte simplu asamblată, fundalul este relizat într-o tentativă la tehnica pouring replicată conform unor tutoriale de pe YouTube, peste acesta este pozitionat Vericu`.
                    </p>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light pl-8'}>
                        Vericu este o reprezentare a unei entități colective, arhetipul individului care suprasaturează scena artistică actuală, de acea am folosit ca și referință formatul de memă boomer wojack.
                    </p>
                </div>

                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>PANSY</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/400_10.png'} className={'w-full -ml-8 lg:hidden'} />
                        <div className={'w-full flex flex-col gap-2 p-4'}>
                            <img src={'/assets/400_11.jpg'} className={'w-full'} alt={'Capybara - Alexandra Tirpescu'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Capybara</span> - PANSY</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <img src={'/assets/400_10.png'} className={'object-center w-full'} />
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light pl-8'}>
                        Am ales să fac această lucrare în semn de protest cu o zi înainte de expoziție , pentru că pană acum exista mereu o presiune și o monitorizare a lucrărilor expuse în spațiile publice, Iar având în vedere faptul ca pentru mine a fost mereu o provocare sa trebuiască sa găsesc obiectul unui discurs artistic elevat în orice lucrare, era imposibil să lucrez pur și simplu intuitiv. Astfel , din frustrările mele am ales să fac lucrarea cu o zi înainte de vernisaj fără manipulare , fără scop, fără retușării , tac și fac și așa îmi place.
                    </p>
                </div>

                <div className={'col-span-1 hidden lg:flex border-b border-r border-r-primary border-b-primary p-20'}>
                </div>
                <div className={'col-span-1 hidden lg:flex border-b border-b-primary p-20'}>
                </div>

            </div>
            <div className={'w-full flex flex-col items-center justify-start text-end pl-4 py-4'}>
                <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Lucrari</p>
                <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Colective</p>
            </div>
            <div className={'w-full flex flex-col lg:flex-row items-center justify-center gap-4'}>
                <div className={'lg:w-1/3 w-full flex flex-col gap-2 p-4'}>
                    <img src={'/assets/400_12.jpg'} className={'w-full'} alt={'Untitled'} />
                    <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Untitled</span> - Ioana Mihai, Marian Stavarache, Radu-Mihai Tanasa, Alexandra Tirpescu</p>
                </div>

                <div className={'lg:w-1/3 w-full flex flex-col gap-2 p-4'}>
                    <img src={'/assets/400_13.jpg'} className={'w-full'} alt={'Capybara - Alexandra Tirpescu'} />
                    <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>au mai rămas 399</span> - marean, pansy</p>
                </div>

                <div className={'lg:w-1/3 w-full flex flex-col gap-2 p-4'}>
                    <img src={'/assets/400_14.jpg'} className={'w-full'} alt={'Capybara - Alexandra Tirpescu'} />
                    <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Proiecție pe perete</span> - Ioana Mihai, Marean, Radu-Mihai Tanasa, Pansy</p>
                </div>
            </div>
        </div>
    )
}
