import React from 'react'
import { type Member } from '../types'
import { Link } from 'react-router-dom'

export const MembersPreview = ({ memberPreview }: { memberPreview: Member }): React.JSX.Element => {
    return (
        <Link to={`/members/${memberPreview.id}`}>
            <div className={'w-[150px] h-[150px] md:w-auto md:h-auto relative group cursor-pointer'}>
                <img src={memberPreview.imageBig} className={'w-full h-full object-cover object-center'} alt={''}/>
                <div className={'lg:transition-opacity lg:ease-in lg:duration-300 lg:opacity-0 lg:group-hover:opacity-100 absolute text-white p-1 bg-[#0F0E0E] w-full lg:w-[53px] bottom-0 lg:h-full lg:[writing-mode:vertical-lr] lg:rotate-180 text-center text-xs lg:text-2xl lg:right-0'}>
                    <span className={'uppercase font-bold'}>{memberPreview.firstName}</span>
                    <span> </span>
                    <span className={'uppercase font-light'}>{memberPreview.lastName}</span>
                </div>
            </div>
        </Link>
    )
}
