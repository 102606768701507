import React, { useEffect, useState } from 'react'
import { emptyMember, type Member, memberPreviews } from '../types'
import { useParams } from 'react-router-dom'
import { MemberInfoMenu, MemberInfoImageWithLink } from '../components'
import { MemberInfoNameAndDescription } from '../components/MemberInfoNameAndDescription'

export const MemberInfoPage = (): React.JSX.Element => {
    const { memberId } = useParams() ?? ''
    const [currentMember, setCurrentMember] = useState<Member>(emptyMember)

    useEffect(() => {
        const member = memberPreviews.find((member, idx) => {
            return member.id === memberId
        })

        setCurrentMember(member ?? emptyMember)
    }, [])

    return (
        <div className={'flex flex-row gap-4 w-full'}>
            <img src={'/assets/members_title.svg'} alt={''} className={'h-full pt-16 hidden lg:block'} />
            <div className={'flex flex-col w-full gap-32'}>
                <div className={'flex flex-row gap-4 xl:gap-8'}>
                    <MemberInfoImageWithLink currentMember={currentMember} />
                    <MemberInfoNameAndDescription currentMember={currentMember} />
                </div>
                <MemberInfoMenu currentMemberId={currentMember.id} />
            </div>
        </div>
    )
}
