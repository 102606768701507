import React from 'react'
import { type Project } from '../types'
import { Separator, TextButton } from '../../../components'
import { Link } from 'react-router-dom'

export const ProjectBalkanY2k = ({ project }: { project: Project }): React.JSX.Element => {
    return (
        <div className={'flex flex-col gap-8 p-4 lg:max-w-[80%]'}>
            <div className={'flex flex-col-reverse lg:flex-row justify-start gap-4 lg:justify-between'}>
                <div className={'flex flex-col border-b border-primary py-2 w-full lg:w-1/4 cursor-pointer'}>
                    <p className={'font-medium text-primary lg:text-3xl xl:text-5xl text-4xl uppercase'}>{project.name}</p>
                    <p className={'font-normal text-grey uppercase'}>{project.date}</p>
                </div>
                <div className={'flex flex-row justify-center items-center w-full lg:w-1/2'}>
                    <Link to={'https://sites.google.com/view/balkany2k/home'} className={'w-full'} target={'_blank'}>
                        <TextButton className={'px-8 py-2 w-full'} text={'galerie virtuala'} />
                    </Link>
                    <Separator className={'bg-primary w-full'} />
                </div>
            </div>

            <div className={'w-full flex flex-col gap-4'}>
                <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                    Pe măsură ce observăm succesul care este tendința de Y2K Revival care se răspândește peste tot în lume, este important să ne uităm înapoi la acea epocă și să ne întrebăm: ai cui anii 2000 îi reînviam cu adevărat?
                </p>
                <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                    Pentru mulți oameni din afara Europei de Vest și a SUA, Y2K-urile erau foarte diferite de imaginile care circulă astăzi. În timp ce copiii din Marea Britanie își cumpărau primele PS1, sau chiar PS2, în țările post-comuniste versiunile bootleg ale SNES sau SEGA Genesis au rămas populare în anii 90 și 00, doar pentru a fi înlocuite în cele din urmă de computerul personal de acasă. Numai în anii 90 și 2000 hiturile și tendințele americane din anii 80 și 90 au devenit parte a culturii locale. Chiar și astăzi, se pare că suntem întotdeauna cu 10 ani în spatele culturii curente din SUA. Deci întrebarea este: cum a fost „Y2K” Europa de Est din anii 2000 în comparație cu omologul său occidental?Putem privi colectiv înapoi la acea epocă și putem analiza critic situația noastră culturală și digitală trecută și actuală? Ce avem de câștigat?
                </p>
                <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                    Acestea sunt câteva dintre întrebările pe care și le-au pus și cei 12 artiști selectați; fie prin colaj, pictură, video art sau chiar modele în miniatură. De la întrebări despre auto-colonizare, nostalgie și fetișism al mărfurilor, identitatea a doua generație sau mixtă de migranți, până la apariția unor comunități virtuale consacrate - elementul recurent este privirea de sine, întotdeauna atât critică, cât și susținătoare, atât emancipatoare, cât și reflectorizante, extrem de personală. în timp ce încă conștient din punct de vedere social.
                </p>
                <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                    Pentru fiecare opera de artă, artistului i s-a cerut să scrie o scurtă reflecție personală asupra lucrării și a contextului din jur. Acest lucru a fost făcut într-un efort de a oferi mai multă agenție artiștilor și de a capta vocea lor unică și personală.
                </p>

                <div className={'mt-8 py-8 flex flex-col gap-4 border-t border-t-primary'}>
                    <p className={'text-3xl lg:text-5xl uppercase font-normal'}>descriere proiect</p>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Cu ajutorul The Wrong Biennale, Apartament 6, un colectiv independent cu sediul la Iași,
                        România, își propune să documenteze amintirile colective pe care le deține partea de est a
                        continentului european din anii 2000.
                    </p>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Artiștii sunt invitați să împărtășească lucrări de artă care reflectă propria lor experiență
                        personală de a trăi prin Y2K, cu accent pe modul în care tendințele occidentale și bunurile de
                        consum au fost integrate în spațiul mai larg al Europei de Est.
                    </p>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Fie că este prima dată când vezi Disney Channel la televizor la prima ta petrecere LAN pentru
                        a pretinde că joci Counterstrike cu prietenii tăi pe stradă, ne interesează în special poveștile
                        personale și lucrările de artă care arată îmbinarea spațiilor estice cu produsele (intelectuale)
                        occidentale.
                    </p>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Lucrările de artă selectate vor fi afișate online, pe un pavilion The Wrong Biennale: un site web dedicat unde vizitatorii vor putea răsfoi trimiterile din Balkan Y2K într-un mediu virtual interactiv similar cu vechile jocuri Wolfenstein 3D/DOOM.
                    </p>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Credem că această alegere reflectă mai bine dihotomiile Est/Vest prezente la începutul anilor 2000.
                    </p>
                    <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                        Acest proiect este autofinanțat și o scrisoare de dragoste către locurile în care am crescut.
                    </p>
                </div>
            </div>
        </div>
    )
}
