import React from 'react'
import { TextButton } from '../../../components'
import { type Member } from '../types'

export const MemberInfoImageWithLink = ({ currentMember }: { currentMember: Member }): React.JSX.Element => {
    return (
        <div className={'hidden lg:flex flex-col gap-8 2xl:min-w-[230px] lg:min-w-[180px]'}>
            <img src={`${currentMember.imageBig}`} alt={''} />
            {
                (currentMember.portfolio != null) ? <TextButton text={'portfolio'} onClick={() => { window.open(currentMember?.portfolio ?? '') }} /> : ''
            }
            {
                (currentMember.website != null) ? <TextButton text={'website'} onClick={() => { window.open(currentMember?.website ?? '') }} /> : ''
            }
            {
                (currentMember.instagram != null) ? <span className={'text-center text-md cursor-pointer'} onClick={() => { window.open(currentMember?.instagram ?? '') }}>{currentMember?.instagramTag}</span> : ''
            }
        </div>
    )
}
