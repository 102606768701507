import { type Project } from '../types'
import React from 'react'
import classNames from 'classnames'
import { Link, useNavigate } from 'react-router-dom'

export const ProjectMenuItem = ({ project, orientation }: { project: Project, orientation: string }): React.JSX.Element => {
    const navigate = useNavigate()

    return (
        <div className={classNames('flex lg:py-4 lg:pr-48', {
            'flex-row justify-start -ml-4': orientation === 'left',
            'flex-row-reverse justify-end -mr-4': orientation === 'right'
        })}>
            <div className={'flex flex-col gap-8'}>
                <Link to={`/projects/${project.id}`}>
                    <div className={'flex flex-col border-b border-primary p-4 min-w-1/4 cursor-pointer'}>
                        <p className={'font-medium text-primary lg:text-3xl xl:text-5xl uppercase'}>{project.name}</p>
                        <p className={'font-normal text-grey uppercase'}>{project.date}</p>
                    </div>
                </Link>

                <div className={classNames('w-full flex lg:hidden', {
                    'justify-start': orientation === 'left',
                    'justify-end': orientation === 'right'
                })}>
                    <Link to={`/projects/${project.id}`}>
                        <img className={'object-contain cursor-pointer max-h-[400px] '} src={project.menuImage} />
                    </Link>

                </div>
                <p className={'font-light text-lg px-4 text-justify line-clamp-6'}>{project.description}</p>
            </div>

            <img onClick={() => { navigate(`/projects/${project.id}`) }} className={'p-8 border-primary border object-cover cursor-pointer hidden lg:flex'} src={project.menuImage} />
        </div>
    )
}
