import React from 'react'
import { MenuItemList } from '../../../components/MenuItemList'

export const LandingPageMenu = (): React.JSX.Element => {
    return (
        <nav className={'w-full h-auto'}>
            <ul className={'text-center text-white flex flex-col lg:flex-row gap-6 lg:items-center lg:justify-center'}>
                <MenuItemList />
            </ul>
        </nav>
    )
}
