import React from 'react'
import { type Member } from '../types'
import { TextButton } from '../../../components'

export const MemberInfoNameAndDescription = ({ currentMember }: { currentMember: Member }): React.JSX.Element => {
    return (
        <div className={'flex flex-col gap-8'}>
            <div className={'flex flex-row lg:flex-col justify-end items-end uppercase gap-2 lg:items-start px-8  lg:pr-0'}>
                <span className={'text-3xl lg:text-9xl break-keep'}>{currentMember.firstName}</span>
                <span className={'pl-2 text-xl'}>{currentMember?.lastName}</span>
            </div>
            <img src={`${currentMember.imageSm}`} className={'h-auto lg:hidden -mx-4'} alt={''}/>
            {
                currentMember.description.map((descr, idx) => (
                    <p key={idx} className={'lg:p-8 text-lg font-light leading-8'}>{descr}</p>
                ))
            }
            <div className={'w-ful flex flex-col gap-8 lg:hidden'}>
                {
                    (currentMember.portfolio != null) ? <TextButton text={'portfolio'} onClick={() => { window.open(currentMember?.portfolio ?? '') }} /> : ''
                }
                {
                    (currentMember.website != null) ? <TextButton text={'website'} onClick={() => { window.open(currentMember?.website ?? '') }} /> : ''
                }
                {
                    (currentMember.instagram != null) ? <span className={'text-center text-md cursor-pointer'} onClick={() => { window.open(currentMember?.instagram ?? '') }}>{currentMember?.instagramTag}</span> : ''
                }
            </div>
        </div>
    )
}
