import React from 'react'

interface FooterProps {
    className?: string
}

export const Footer = (props: FooterProps): React.JSX.Element => {
    return (
        <footer className={`w-full mt-auto min-h-[56px] text-center text-white text-sm bg-secondary flex justify-center items-center ${props.className ?? ''}`}>
            <span className={'select-none'}>Copyright © AP 6 2023 </span>
        </footer>
    )
}
