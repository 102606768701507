import React, { useEffect, useState } from 'react'
import { DesktopMenu, Footer, MobileMenu } from '../../../components'
import { Link, useParams } from 'react-router-dom'
import { emptyProject, type Project, projectById, projects } from '../types'
import { Project441LeiContents } from '../pages'
import { ProjectBalkanY2k } from '../pages/ProjectBalkanY2k'
import { Project400LeiContents } from '../pages/Project400LeiContents'
import { ProjectEstulSalbaticContents } from '../pages/ProjectEstulSalbaticContents'
import { ProjectCompromis } from '../pages/ProjectCompromis'

export const ProjectPageLayout = ({ title }: { title?: string }): React.JSX.Element => {
    const { projectId } = useParams() ?? ''
    const [currentProject, setCurrentProject] = useState<Project>(emptyProject)
    const [menuTheme, setMenuTheme] = useState<string>('light')

    const updateMenuThemeOnScroll = (): void => {
        if (window.scrollY > 300) {
            setMenuTheme('dark')
        } else if (window.scrollY < 300) {
            setMenuTheme('light')
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', updateMenuThemeOnScroll, { passive: true })

        return () => {
            window.removeEventListener('scroll', updateMenuThemeOnScroll)
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)

        const project = projects.find((project, idx) => {
            return project.id === projectId
        })

        setCurrentProject(project ?? emptyProject)
    }, [])

    const componentPerId = (projectId: string): React.JSX.Element | null => {
        switch (projectId) {
            case '441.2_lei':
                return <Project441LeiContents project={currentProject} />
            case 'balkan.y2k':
                return <ProjectBalkanY2k project={currentProject} />
            case '400.lei':
                return <Project400LeiContents project={currentProject} />
            case 'estul.salbatic':
                return <ProjectEstulSalbaticContents project={currentProject} />
            case 'compromis':
                return <ProjectCompromis project={currentProject} />
        }

        return null
    }

    return (
        <>
            <div className={'w-full h-full flex flex-row'}>
                <DesktopMenu theme={menuTheme}/>
                <div className={'w-full flex flex-col'}>
                    <div className={'lg:hidden w-full min-h-[72px] max-h-[72px] bg-light block'}>
                        <MobileMenu title={title}/>
                    </div>
                    <div className={'w-full h-full flex flex-col'}>
                        <div className={'w-full h-[206px] lg:h-[640px] bg-secondary relative'}>

                            <img src={currentProject.coverImage} className={'w-full h-full object-cover'} />

                            <div className={'absolute w-full z-1 bottom-0 lg:bottom-[500px] bg-primary/30 lg:bg-transparent flex flex-row items-center justify-center border lg:border-none border-light'}>
                                <Link to={`/projects/${currentProject.prevId}`} reloadDocument={true} className={'w-1/2 lg:w-1/4 flex flex-row justify-between cursor-pointer lg:p-4 p-2 uppercase text-lg lg:text-2xl font-bold hover:bg-green border-r border-b border-light'}>
                                    <img src={'/assets/left_arrow.svg'} className={'fill-light'} />
                                    <p className={'text-light/50'}>{projectById(currentProject.prevId).name}</p>
                                </Link>
                                <Link to={`/projects/${currentProject.nextId}`} reloadDocument={true} className={'w-1/2 lg:w-1/4 flex flex-row justify-between cursor-pointer lg:p-4 p-2 uppercase text-lg lg:text-2xl font-bold hover:bg-green border-b border-light'}>
                                    <p className={'text-light/50'}>{projectById(currentProject.nextId).name}</p>
                                    <img src={'/assets/right_arrow.svg'} className={'fill-light'} />
                                </Link>
                            </div>
                        </div>
                        <div className={'min-h-[1000px] lg:ml-[373px] py-8 px-4'}>
                            {
                                componentPerId(currentProject.id) ?? <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}
