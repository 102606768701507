export interface Member {
    firstName: string
    lastName: string
    imageBig: string
    imageSm: string
    description: string[]
    id: string
    portfolio?: string
    instagram?: string
    instagramTag?: string
    website?: string
}

export const memberPreviews: Member[] = [
    {
        firstName: 'Cristiana',
        lastName: 'Ursache',
        imageBig: '/assets/members_cristiana.jpg',
        imageSm: '/assets/members_cristiana_sm.jpg',
        description: ['Cristiana Ursache este curator în Iași, România. Ea își propune să aducă arta mai aproape de public și să creeze conexiuni semnificative între artiști și privitori, având grijă ca orice expoziție să fie o experiență vizuală ce are parte și de mediere culturală. Practicile sale s-au concentrat inițial pe o abordare istorică, dezvoltând ulterior o predilecție pentru arta contemporană și manifestările artistice comuniste și postcomuniste românești.'],
        id: 'cristiana.ursache'
    },
    {
        firstName: 'Ioana',
        lastName: 'Mihai',
        imageBig: '/assets/members_ioana.jpg',
        imageSm: '/assets/members_ioana_sm.jpg',
        description: ['Ioana Mihai este o artistă vizuală emergentă care își desfălăară activitatea în București, România. Ea dezvoltă teme cu dimensiuni sociale și culturale cu scopul de a crește gradul de conștientizare a preocupărilor comunității în care activează. Pentru a-și exprima discursul, abordează tehnici multidisciplinare, care îmbină pictura, sculptura sau instalațiile.'],
        id: 'ioana.mihai',
        portfolio: 'https://l.facebook.com/l.php?u=https%3A%2F%2Fdrive.google.com%2Ffile%2Fd%2F1g1aO_frAX8AYrH-cKbKK3GrbQWoiKyz7%2Fview%3Fusp%3Ddrive_link%26fbclid%3DIwAR2vT2Ebp4sCODT8TsYrUkpDM6jUNdv2M4RZaKX_9DrW6e__K1DLvSHwOs4&h=AT1pNfKmcQiMyuDOnEtt7ZlBWyRVYuexlgECoQIPtGykzLP8bl3covL4sSX9zbrOwZaOgROB95wY4ORYSTLntZjRYWbvf6S-F0e_sihVZ2suZnnMSTz_IzfrenIM3w',
        instagram: 'https://www.instagram.com/ioanaeartista',
        instagramTag: '@ioanaeartista'
    },
    {
        firstName: 'Laura',
        lastName: 'Stoica',
        imageBig: '/assets/members_laura.jpg',
        imageSm: '/assets/members_laura_sm.jpg',
        description: ['Sunt o artistă vizuală și regizoare de animație profund inspirată de tradițiile culturale și moștenirea spirituală din Europa de Est. Lucrările mele se bazează pe varietatea bogată de teme și motive folclorice care au fost transmise de-a lungul generațiilor, explorând astfel complexitatea experienței umane.', 'Pentru mine, spiritualitatea și religia nu sunt doar concepte abstracte, ci sunt adânc înrădăcinate în pământ și în oamenii care îl locuiesc. Prin arta mea, caut să surprind esența acestei conexiuni. \n' +
        'De asemenea, moartea este un motiv recurent în creația mea. Indiferent dacă lucrez cu media tradițională sau experimentez cu noi forme de artă digitală si instalatii, abordarea mea este întotdeauna caracterizată de un profund sentiment de vulnerabilitate.'],
        id: 'laura.stoica',
        portfolio: 'https://drive.google.com/file/d/1F-Es1IWIBL4MkKCVhZPQkHf9OAcZZiMy/view?usp=drive_link'
    },
    {
        firstName: 'Marean',
        lastName: '',
        imageBig: '/assets/members_marian.jpg',
        imageSm: '/assets/members_marian_sm.jpg',
        description: ['Marean este un artist multimedia român. Cea mai mare parte din producția sa artistică explorează, dar nu se limitează, la teme care variază de la cultura internetului la diferitele dimensiuni ale violenței, neglijării și degradării. În mare parte natura muncii sale artistice se prezintă printr-o  abordare brută, imprudentă și experimentală, statementul artistic luând formă prin imagini îndrăznețe ce participă la crearea unui spațiu ciudat, abstract și nedefinit.'],
        id: 'marian.stavarache',
        portfolio: 'https://drive.google.com/file/d/1TD5d6i99lekuFfK9eOfELze4KcK5RsS1/view'
    },
    {
        firstName: 'Pansy',
        lastName: '',
        imageBig: '/assets/members_alexandra.jpg',
        imageSm: '/assets/members_alexandra_sm.jpg',
        description: ['Pansy este o artistă multimedia din Iași, România. Interesată de estetica vizuală a României anilor 2000, Pansy refuză. atât înscrierea în categorii clasice de artă vizuală, cât și asumarea unui discurs artistic transparent. \n' +
        'Prin asumarea unei poziționări artistice voit opace, Pansy prefera discursul artistului comunicat prin intermediul lucrărilor sale;', 'Pansy investighează posibilitățile mediului ei ales și este întotdeauna centrata pe practica ei și, prin îmbinarea temelor mari ale picturii istorice cu formele contemporane, lucrările lui Pansy nu se referă doar la subiectul lor specific, ci și la actul picturii în sine. Bazându-se pe diverse surse, artistul reconfigurează amintirile personale, referințele istorice ale artei, fotografiile de film și mediile vizuale colectate de pe internet în imaginile operei sale'],
        id: 'alexandra.tirpescu',
        portfolio: 'https://drive.google.com/file/d/1HwpTiVya0e8jNrvqoww5pln_75kqcH0N/view'
    },
    {
        firstName: 'Radu-Mihai',
        lastName: 'TĂNASĂ',
        imageBig: '/assets/members_radu.png',
        imageSm: '/assets/members_radu_sm.jpg',
        description: ['Sunt un artist emergent a cărui practică artistică se preocupă cu tematici precum disforia culturală, identitatea masculin-balcanică și condiția de emigrant. Lucrez predominant cu video, performance și software art.', 'În ultima vreme sunt continuu inspirat de scena autohtonă de manele și de impactul pe care această cultură o are asupra comunităților din diaspora atât la nivel vizual, cât și discursiv.', 'În timpul liber mai fac și muzică experimentală.'],
        id: 'radu.tanasa',
        website: 'https://radu-mihai.com/',
        instagram: 'https://www.instagram.com/radutheguy',
        portfolio: 'https://drive.google.com/file/d/1bGEzRpA0BKD7FuzhO5qEWG_BvRQ9U7oj/view',
        instagramTag: '@radutheguy'
    },
    {
        firstName: 'Ruxandra',
        lastName: 'MĂRGINEANU',
        imageBig: '/assets/members_ruxandra.png',
        imageSm: '/assets/members_ruxandra_sm.png',
        description: ['Experiența personală joacă un rol central în parcursul meu artistic, încercând să dezvolt subiecte atât din amintiri, cât și din observație, provenind din cultura și aspectul României din timpul și după regimului comunist.', 'Fiind la începutul carierei, caut să explorez cât mai multe tehnici, dar momentan prezint interes pentru animația tradițională și tipografie, cu care încerc să prezint elemente diferite din Europa de Est într-o manieră neutră, fără să îmi asum rolul de critic sau moralizator.'],
        id: 'ruxandra.margineanu',
        portfolio: 'https://www.behance.net/ruxandramargineanu'
    }
]

export const emptyMember = {
    description: [],
    firstName: '',
    id: '',
    imageBig: '',
    imageSm: '',
    lastName: ''
}
