import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { LandingPage } from './features/landingpage/LandingPage'
import { AboutPage } from './features/about/AboutPage'
import { MembersPage } from './features/members'
import { ContactPage } from './features/contact'
import { MemberInfoPage } from './features/members/pages/MemberInfoPage'
import { WebsiteLayout } from './components'
import { ProjectsPage } from './features/projects/pages'
import { ProjectPageLayout } from './features/projects/components/ProjectPageLayout'

export const App = (): React.JSX.Element => {
    return (
        <Routes>
            <Route path="/" element={<LandingPage/>} />
            <Route path="/about" element={<WebsiteLayout title={'despre'}><AboutPage/></WebsiteLayout>} />
            <Route path="/members" element={<WebsiteLayout title={'membri'}><MembersPage/></WebsiteLayout>} />
            <Route path="/members/:memberId" element={<WebsiteLayout title={'membri'}><MemberInfoPage/></WebsiteLayout>} />
            <Route path="/contact" element={<WebsiteLayout title={'contact'}><ContactPage/></WebsiteLayout>} />
            <Route path="/projects" element={<WebsiteLayout title={'projects'}><ProjectsPage/></WebsiteLayout>} />
            <Route path="/projects/:projectId" element={<ProjectPageLayout title={'projects'}/>} />
        </Routes>
    )
}
