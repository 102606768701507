import { type Project } from '../types'
import React from 'react'

export const ProjectCompromis = ({ project }: { project: Project }): React.JSX.Element => {
    return (
        <div className={'flex flex-col gap-8 p-4 lg:max-w-[80%]'}>
            <div className={'flex flex-col-reverse lg:flex-row justify-start gap-4 lg:justify-between'}>
                <div className={'flex flex-col border-b border-primary py-2 w-full lg:w-1/2 cursor-pointer'}>
                    <p className={'font-medium text-primary lg:text-3xl xl:text-5xl text-4xl uppercase'}>{project.name}</p>
                    <p className={'font-normal text-grey uppercase'}>{project.date}</p>
                </div>
            </div>

            <div className={'flex flex-col py-4'}>
                <p className={'uppercase text-2xl'}>aRGUMENT cURATORIAL</p>
                <p className={'uppercase text-md text-grey'}>cRISTIANA uRSACHE</p>
            </div>

            <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                Schimbând unghiul standard de vedere al individului șablonat și aducând o orientare intrinsecă individuală, se ridică adesea problematizări diverse privind conflicte interioare ample. Ele pot fi de natură socială, politică, istorică și nu numai, având ca determinant o puternică amprentă integrantă personală. Filtrul particular, consolidat de back-ground-ul unic al fiecărei persoane este generatorul de compromisuri care trebuie făcute pentru a te ajusta sistematic standardului, asemenea modelului unui pat a lui Procust. Indignarea adresată acestui gen de sistematizare a umanității este cea care a adus împreună pe cei cinci expozanți ce invoca propriile experiențe materializate la nivel vizual, exprimând ce a însemnat pentru ei compromisul în multimplele ipostaze ale sale.
            </p>
            <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                Conturând o traiectorie dinspre exteriorul social ce se implementează subversiv în structura artiștilor, se concretizează lucrările de față ca un ricoșeu ce pericliteză ansamblul prestabilit și care au mai mult decât un cuvânt de spus.
            </p>

            <div className={'grid grid-cols-2 border-t border-t-primary'}>
                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Radu-Mihai</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Tănasă</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/compromis_01.jpg'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                            Compromisul resimțit de Radu-Mihai Tanasă este la nivelul puterii exercitate inopinat asupra propriei personalități, situându-se în universul politic disonant dar de nestrămutat. El întruchipează un colectiv uman care, în zilele noastre și-a mărit numărul, reprezentând realmente o voce, o atitudine.
                        </p>
                        <div className={'w-full flex flex-col gap-2 p-4'}>
                            <img src={'/assets/compromis_02.jpg'} className={'w-full'} alt={'TAUR - RADU - MIHAI TĂNASĂ'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>TAUR</span> - RADU - MIHAI TĂNASĂ</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4 lg:hidden'}>
                            <img src={'/assets/compromis_03.jpg'} className={'w-full'} alt={'ECUCUARIOTA - RADU - MIHAI TĂNASĂ'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>ECUCUARIOTA</span> - RADU - MIHAI TĂNASĂ</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                            <img src={'/assets/compromis_04.jpg'} className={'w-full'} alt={'FUTĂCIUNEA ZBURĂTOARE - RADU - MIHAI TĂNASĂ'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>FUTĂCIUNEA ZBURĂTOARE</span> - RADU - MIHAI TĂNASĂ</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4 lg:hidden'}>
                            <img src={'/assets/compromis_05.jpg'} className={'w-full'} alt={'Cynocephalis - RADU - MIHAI TĂNASĂ'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Cynocephalis</span> - RADU - MIHAI TĂNASĂ</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                            <img src={'/assets/compromis_06.jpg'} className={'w-full'} alt={'Cynocephalis ii - RADU - MIHAI TĂNASĂ'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Cynocephalis ii</span> - RADU - MIHAI TĂNASĂ</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                            <img src={'/assets/compromis_07.jpg'} className={'w-full'} alt={'SCRIAPOD - RADU - MIHAI TĂNASĂ'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>SCRIAPOD</span> - RADU - MIHAI TĂNASĂ</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4 lg:hidden'}>
                            <img src={'/assets/compromis_08.jpg'} className={'w-full'} alt={'HIMERA DREPTURiLOR DE AUTOR - RADU - MIHAI TĂNASĂ'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>HIMERA DREPTURiLOR DE AUTOR</span> - RADU - MIHAI TĂNASĂ</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4 lg:hidden'}>
                            <img src={'/assets/compromis_09.jpg'} className={'w-full'} alt={'dEMONUL JARGONULUI CORPORATIST RADU - MIHAI TĂNASĂ'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>dEMONUL JARGONULUI CORPORATIST</span> - RADU - MIHAI TĂNASĂ</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/compromis_01.jpg'} className={'w-full'} alt={'HIENA - RADU - MIHAI TĂNASĂ'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>HIENA</span> - RADU - MIHAI TĂNASĂ</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/compromis_03.jpg'} className={'w-full'} alt={'ECUCUARIOTA - RADU - MIHAI TĂNASĂ'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>ECUCUARIOTA</span> - RADU - MIHAI TĂNASĂ</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/compromis_05.jpg'} className={'w-full'} alt={'Cynocephalis - RADU - MIHAI TĂNASĂ'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>Cynocephalis</span> - RADU - MIHAI TĂNASĂ</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/compromis_08.jpg'} className={'w-full'} alt={'HIMERA DREPTURiLOR DE AUTOR - RADU - MIHAI TĂNASĂ'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>HIMERA DREPTURiLOR DE AUTOR</span> - RADU - MIHAI TĂNASĂ</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/compromis_09.jpg'} className={'w-full'} alt={'dEMONUL JARGONULUI CORPORATIST RADU - MIHAI TĂNASĂ'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>dEMONUL JARGONULUI CORPORATIST</span> - RADU - MIHAI TĂNASĂ</p>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 lg:border-r lg:border-r-primary'}>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                        <img src={'/assets/compromis_10.jpg'} className={'w-full'} alt={'2005 - Ruxandra Mărgineanu'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>2005</span> - Ruxandra Mărgineanu</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                        <img src={'/assets/compromis_11.jpg'} className={'w-full'} alt={'2005 II - Ruxandra Mărgineanu'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>2005 II</span> - Ruxandra Mărgineanu</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                        <img src={'/assets/compromis_13.jpg'} className={'w-full'} alt={'2005 IV - Ruxandra Mărgineanu'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>2005 IV</span> - Ruxandra Mărgineanu</p>
                    </div>
                </div>

                <div className={'col-span-full lg:col-span-1  pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-start justify-start text-end pl-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Ruxandra</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Mărgineanu</p>
                    </div>
                    <div className={'lg:py-8 lg:pl-8 w-full flex flex-col gap-8'}>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                            Impactul pe care l-a avut situația politică autohtonă recentă este profilat de Ruxandra Mărgineanu sub forma concordanței configurate între literatura de presă și evenimentele vehiculate prin intermediul acesteia, evidențiind faptul ci spațiul utilizat este de multe ori nepotrivit (de tip can-can).
                        </p>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8 lg:hidden'}>
                            <img src={'/assets/compromis_10.jpg'} className={'w-full'} alt={'2005 - Ruxandra Mărgineanu'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>2005</span> - Ruxandra Mărgineanu</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8 lg:hidden'}>
                            <img src={'/assets/compromis_11.jpg'} className={'w-full'} alt={'2005 II - Ruxandra Mărgineanu'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>2005 II</span> - Ruxandra Mărgineanu</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                            <img src={'/assets/compromis_12.jpg'} className={'w-full'} alt={'2005 III - Ruxandra Mărgineanu'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>2005 III</span> - Ruxandra Mărgineanu</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8 lg:hidden'}>
                            <img src={'/assets/compromis_13.jpg'} className={'w-full'} alt={'2005 IV - Ruxandra Mărgineanu'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>2005 IV</span> - Ruxandra Mărgineanu</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                            <img src={'/assets/compromis_14.jpg'} className={'w-full'} alt={'2005 V - Ruxandra Mărgineanu'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>2005 V</span> - Ruxandra Mărgineanu</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                            <img src={'/assets/compromis_15.jpg'} className={'w-full'} alt={'2005 VI - Ruxandra Mărgineanu'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>2005 VI</span> - Ruxandra Mărgineanu</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>MAREAN</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/compromis_16.jpg'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                            Marian Stavarache plasează compromisul în inadecvarea metodelor de exprimare acceptate de către socitate care îngrădesc libertatea exteriorizării manifestărilor integre și dau naștere convențiilor social-formale redundante.
                        </p>
                        <div className={'w-full flex flex-col gap-2 p-4'}>
                            <img src={'/assets/compromis_17.jpg'} className={'w-full'} alt={'6'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>6</span> - MarEAN</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4 lg:hidden'}>
                            <img src={'/assets/compromis_18.jpg'} className={'w-full'} alt={'ALMOST 3/2 MarEAN'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>ALMOST 3/2</span> - MarEAN</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <div className={'w-full flex flex-col gap-2 p-4'}>
                        <img src={'/assets/compromis_16.jpg'} className={'w-full'} alt={'bîZA MarEAN'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>bîZA</span> - MarEAN</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/compromis_18.jpg'} className={'w-full'} alt={'ALMOST 3/2 MarEAN'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>ALMOST 3/2</span> - MarEAN</p>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 lg:border-r lg:border-r-primary'}>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                        <img src={'/assets/compromis_19.jpg'} className={'w-full'} alt={'JURNAL PANSY'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>JURNAL</span> - PANSY</p>
                    </div>
                </div>

                <div className={'col-span-full lg:col-span-1  pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-start justify-start text-end pl-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>pANSY</p>
                    </div>
                    <div className={'lg:py-8 lg:pl-8 w-full flex flex-col gap-8'}>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                            Traiectoria compromisului este orientată către latura intimă a umanului prin intermediul lucrărilor Alexandrei Tirpescu care joaca rolul unui jurnal simbolic al elementelor primordiale constitutive ale OM-ului, elemente ce au de suferit prin periclitarea insistentă și conștientă facută de către aparatul comunitar. Tiparul prin care trebuie acceptat raportul dintre rațiune și iubire în concordanță cu trecerea timpului creionează un personaj defalcat ca rezultat al cerințelor parazitare impuse.
                        </p>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8 lg:hidden'}>
                            <img src={'/assets/compromis_19.jpg'} className={'w-full'} alt={'JURNAL PANSY'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>JURNAL</span> - PANSY</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                            <img src={'/assets/compromis_20.jpg'} className={'w-full'} alt={'JURNAL PANSY'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>JURNAL</span> - PANSY</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>LAURA</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>STOICA</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/compromis_21.png'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                            Laura Stoica completează infățișarea compromisului apelând la conjunctura în care nucleul familial este discreditat prin aplicarea unor canoane tipizate impropii. Prin urmare, individul este modificat și structurat încă din sânul familiei pentru a se standardiza conform tiparului, neținându-se cont de compromisul făcut și de consecințele datorate acestuia, repercutate în subsidiar.
                        </p>
                        <div className={'w-full flex flex-col gap-2 p-4'}>
                            <img src={'/assets/compromis_22.jpg'} className={'w-full'} alt={'vICKY i LAURA STOICA'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>vICKY I</span> - LAURA STOICA</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4 lg:hidden'}>
                            <img src={'/assets/compromis_23.jpg'} className={'w-full'} alt={'ALMOST 3/2 MarEAN'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>vICKY II</span> - LAURA STOICA</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-4'}>
                            <img src={'/assets/compromis_24.jpg'} className={'w-full'} alt={'vICKY iii LAURA STOICA'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>vICKY III</span> - LAURA STOICA</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <div className={'w-full flex flex-col gap-2 p-4'}>
                        <img src={'/assets/compromis_21.png'} className={'w-full'} alt={'tRIBUTE TO MY CHILDHOOD lAURA STOICA'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>tRIBUTE TO MY CHILDHOOD</span> - lAURA STOICA</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/compromis_23.jpg'} className={'w-full'} alt={'ALMOST 3/2 MarEAN'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>vICKY II</span> - LAURA STOICA</p>
                    </div>
                </div>

                <div className={'col-span-1 hidden lg:flex border-b border-r border-r-primary border-b-primary p-20'}>
                </div>
                <div className={'col-span-1 hidden lg:flex border-b border-b-primary p-20'}>
                </div>
            </div>
        </div>
    )
}
