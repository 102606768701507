import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { MenuItemList } from './MenuItemList'

interface MobileMenuProps {
    title?: string
}

export const MobileMenu = ({ title }: MobileMenuProps): React.JSX.Element => {
    const [opened, setOpened] = useState<boolean>(false)

    const openCloseMenu = (): void => {
        setOpened(!opened)
    }

    return (
        <div className={'lg:hidden w-full fixed top-0 z-50 flex-col justify-center'}>
            <div className={'h-[72px] bg-secondary flex flex-row justify-center cursor-pointer items-center p-4'} onClick={openCloseMenu}>
                <img src={opened ? '/assets/burger_menu_close.svg' : '/assets/burger_menu.svg'} className={`${opened ? 'p-2' : 'p-1'} w-[35px] h-[35px] cursor-pointer`} alt={'Menu Icon'}/>
                <div className={'w-full text-white text-center font-light select-none pr-4'}>
                    <span className={'uppercase text-xs'}>{title} / <span className={'text-2xl'}>{title} /</span> <span className={'text-xs'}>{title}</span> </span>
                </div>
            </div>

            <div className={`${opened ? '' : 'hidden'} w-full h-[106px] bg-primary flex flex-col justify-center items-center`}>
                <Link to={'/'}>
                    <img src={'/assets/logo.svg'} alt={''} className={'p-2'} />
                </Link>
            </div>

            <nav className={`${opened ? '' : 'hidden'} w-full bg-secondary flex flex-col justify-center items-center p-2`}>
                <ul className={'text-center text-white flex flex-col lg:flex-row gap-4 lg:items-center lg:justify-center'}>
                    <MenuItemList onItemClicked={() => { setOpened(false) }} />
                </ul>
            </nav>
        </div>
    )
}
