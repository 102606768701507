import React from 'react'
import { type Contact } from '../types'

export const ContactItem = ({ contact, className }: { contact: Contact, className?: string }): React.JSX.Element => {
    return (
        <div className={`${className ?? ''} flex flex-col`}>
            <p className={'text-lg block capitalize text-primary font-bold'}>{contact.name}</p>
            <p className={'text-md block font-light'}>{contact.email}</p>
        </div>
    )
}
