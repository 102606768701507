import React from 'react'
import { contactList } from '../types'
import { ContactItem } from '../components/Contact'
import { Separator } from '../../../components'

export const ContactPage = (): React.JSX.Element => {
    return (
        <div className={'flex flex-col items-start gap-8'}>
            <img src={'/assets/contact_title.svg'} alt={''} className={'h-[230px] pb-24 hidden lg:block'} />
            <ContactItem contact={contactList.main}/>
            <Separator className={'w-full lg:w-3/4 lg:opacity-25'} />
            <div className={'grid grid-cols-3 gap-8'}>
                <p className={'col-span-full uppercase'}><span className={'font-bold'}>Contact</span> Members</p>
                {
                    contactList.members.map((contact, idx) => (
                        <ContactItem key={idx} contact={contact} className={'col-span-full lg:col-span-1'}/>
                    ))
                }
            </div>
        </div>
    )
}
