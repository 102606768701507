import React from 'react'
import { projects } from '../types'
import { ProjectMenuItem } from '../components/ProjectMenuItem'

export const ProjectsPage = (): React.JSX.Element => {
    return (
        <div className={'flex flex-col items-start gap-8'}>
            <img src={'/assets/projects_title.svg'} alt={''} className={'h-[230px] pb-24 hidden lg:block'}/>

            <div className={'flex flex-col gap-8'}>
                {projects.map((project, idx) =>
                    <ProjectMenuItem project={project} key={idx} orientation={idx % 2 === 0 ? 'left' : 'right'} />
                )}
            </div>
        </div>
    )
}
