import { type Project } from '../types'
import React from 'react'

export const ProjectEstulSalbaticContents = ({ project }: { project: Project }): React.JSX.Element => {
    return (
        <div className={'flex flex-col gap-8 p-4 lg:max-w-[80%]'}>
            <div className={'flex flex-col-reverse lg:flex-row justify-start gap-4 lg:justify-between'}>
                <div className={'flex flex-col border-b border-primary py-2 w-full lg:w-1/2 cursor-pointer'}>
                    <p className={'font-medium text-primary lg:text-3xl xl:text-5xl text-4xl uppercase'}>{project.name}</p>
                    <p className={'font-normal text-grey uppercase'}>{project.date}</p>
                </div>
            </div>

            <div className={'flex flex-col py-4'}>
                <p className={'uppercase text-2xl'}>aRGUMENT cURATORIAL</p>
                <p className={'uppercase text-md text-grey'}>cRISTIANA uRSACHE</p>
            </div>

            <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                Estul sălbatic nu este doar o expresie ci reprezintă într-adevăr spațiul estic european, mai exact
                spațiul românesc translatat prin prisma experienței amprentate asupra individului ce s-a dezvoltat aici,
                precum și a modului în care a evoluat colectivitatea din jurul său. Timpul este de asemenea un element
                fundamental al acestei expoziții, fiind vorba în cazul dat despre momentul actual precum și despre trecutul recent,
                de după anii 2000. Artiștii din această expoziție fiind cei ce aparțin generației ’99, au ales să încludă în
                discursul lor vizual numeroase subiecte ce aparent par eterogene pentru un ochi obiectiv dar care de fapt,
                la o analiză chiar superficială se poate remarca cum aceste subiecte se coagulează în jurul societății
                în care artiștii s-au format. Prin acest demers, ei creează un discurs comun, având în spate voci și povești
                diverse. Ei creează din perspective diferite povestea unei generații.
            </p>

            <div className={'grid grid-cols-2 border-t border-t-primary'}>
                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Radu-Mihai</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Tănasă</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/estul_01.png'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                            Pornind de la originile propriei experiențe, Radu-Mihai Tănasă propune o explorare individuală cu privire la fragilitatea memoriei (în special a celei afective).
                            El transpune această sondare într-o lucrare ce extrapolează propriile trăiri la caracteristicile dominante a generației căreia aparține și subliniază contrastele pe care le trasează computer-ul în copilăria sa. Trecând la o nouă etapă în carieră, în Olanda, Radu-Mihai Tănasă introduce în lucrările sale studiul alienării și al tranziției prin prisma unui personaj dominant – The Goat.
                            El provine din est, este extras din mediul său și este transpus într-un mod drastic într-o zonă ce nu cuprinde aceleași substraturi culturale.
                        </p>
                        <div className={'w-full flex flex-col gap-2 p-4'}>
                            <img src={'/assets/estul_03.gif'} className={'w-full'} alt={'cug - RADU-MIHAI TĂNASĂ'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>cug</span> - RADU-MIHAI TĂNASĂ</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4 lg:hidden'}>
                            <img src={'/assets/estul_01.png'} className={'w-full'} alt={'SCAPE GOAT - RADU-MIHAI TĂNASĂ'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>SCAPE GOAT</span> - RADU-MIHAI TĂNASĂ</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4 lg:hidden'}>
                            <img src={'/assets/estul_02.jpg'} className={'w-full'} alt={'TAUR - RADU-MIHAI TĂNASĂ'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>TAUR</span> - RADU-MIHAI TĂNASĂ</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/estul_01.png'} className={'w-full'} alt={'SCAPE GOAT - RADU-MIHAI TĂNASĂ'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>SCAPE GOAT</span> - RADU-MIHAI TĂNASĂ</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/estul_02.jpg'} className={'w-full'} alt={'TAUR - RADU-MIHAI TĂNASĂ'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>TAUR</span> - RADU-MIHAI TĂNASĂ</p>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 lg:border-r lg:border-r-primary'}>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                        <img src={'/assets/estul_04.jpg'} className={'w-full'} alt={'TAUR - RADU-MIHAI TĂNASĂ'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>DIE MY MOTHER IF I LIE U</span> - PANSY</p>
                    </div>
                </div>

                <div className={'col-span-full lg:col-span-1  pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-start justify-start text-end pl-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>pANSY</p>
                    </div>
                    <div className={'lg:py-8 lg:pl-8 w-full flex flex-col gap-8'}>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                            Alexandra Tîrpescu prin lucrarea sa are în vedere iustrarea elementelor simbolice post-comuniste (care se resimt și după anii 2000) și care fac parte dintr-un trecut colectiv nouă, din perioada de formare a acestei generații. Obiectele de uz casnic, lucrurile personale, piesele de mobilier comune, toate acestea sunt încrustate prin câteva exemple în lucrarea ei și contribuie la dorința de apropiere precum și la un sentiment nostalgic.
                        </p>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-4 lg:hidden'}>
                            <img src={'/assets/estul_04.jpg'} className={'w-full'} alt={'DIE MY MOTHER IF I LIE U - PANSY'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>DIE MY MOTHER IF I LIE U</span> - PANSY</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>RUXANDRA</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>MĂRGINEANU</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/estul_05.jpg'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                            Ruxandra Mărgineanu vorbește în seria sa de colaje despre contrastele din peisajul urban românesc, creând compoziții abstractizate. Ele scot în evidență transformările secvențiale ce se pot remarca pe fațadele clădirilor de la începutul anilor 2000 până în prezent, ironizând urbanistica ce promovează o creștere masivă a spațiilor de locuit în zone total nepotrivite cu o planificare urbanistică echilibrată.
                        </p>
                        <div className={'w-full flex flex-col gap-2 p-4'}>
                            <img src={'/assets/estul_06.jpg'} className={'w-full'} alt={'Untitled - Marian Stavarache'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>pEISAJ URBAN RUXANDRA</span> - RUXANDRA MĂRGINEANU</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-4 lg:hidden'}>
                            <img src={'/assets/estul_07.jpg'} className={'w-full'} alt={'Chimp dream 2007 - Marian Stavarache'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>pEISAJ URBAN RUXANDRA</span> - RUXANDRA MĂRGINEANU</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/estul_05.jpg'} className={'w-full'} alt={'pEISAJ URBAN RUXANDRA MĂRGINEANU'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>pEISAJ URBAN RUXANDRA</span> - RUXANDRA MĂRGINEANU</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-4'}>
                        <img src={'/assets/estul_07.jpg'} className={'w-full'} alt={'Chimp dream 2007 - Marian Stavarache'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>pEISAJ URBAN RUXANDRA</span> - RUXANDRA MĂRGINEANU</p>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 lg:border-r lg:border-r-primary'}>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                        <img src={'/assets/estul_08.jpg'} className={'w-full'} alt={'CIRCUS I lAURA STOICA'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>CIRCUS I </span> - lAURA STOICA</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                        <img src={'/assets/estul_10.jpg'} className={'w-full'} alt={'CIRCUS III lAURA STOICA'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>CIRCUS III </span> - lAURA STOICA</p>
                    </div>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                        <img src={'/assets/estul_12.jpg'} className={'w-full'} alt={'CIRCUS V lAURA STOICA'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>CIRCUS V </span> - lAURA STOICA</p>
                    </div>
                </div>

                <div className={'col-span-full lg:col-span-1  pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-start justify-start text-end pl-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Laura</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Stoica</p>
                    </div>
                    <div className={'lg:py-8 lg:pl-8 w-full flex flex-col gap-8'}>
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                            Laura Stoica, contribuie asupra acestui ansamblu de imagini cu un substrat sonor ce apelează la latura afectivă a oamenilor. În momentul redării înregistrărior ascultătorul este purtat printr-o serie de momente ce au rămas în memoria artistei și care se pot identifica într-o memorie a societății din care face parte. Laura Stoica a reușit să extragă o parte a experienței acestei generații ce nu putea fi redată prin imagini: cântecele copiilor în prag de sărbători, sfaturile bunicilor, sunetele clopotelor. De asemenea, motivul circului este recurent în lucrările ei, fiind prezentat asemenea unui bildungsroman, construind traiectoria carierei unui personaj ce activa în acel domeniu.
                        </p>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8 lg:hidden'}>
                            <img src={'/assets/estul_08.jpg'} className={'w-full'} alt={'CIRCUS I lAURA STOICA'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>CIRCUS I </span> - lAURA STOICA</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                            <img src={'/assets/estul_09.jpg'} className={'w-full'} alt={'CIRCUS II lAURA STOICA'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>CIRCUS II </span> - lAURA STOICA</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8 lg:hidden'}>
                            <img src={'/assets/estul_10.jpg'} className={'w-full'} alt={'CIRCUS II lAURA STOICA'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>CIRCUS II </span> - lAURA STOICA</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8'}>
                            <img src={'/assets/estul_11.jpg'} className={'w-full'} alt={'CIRCUS IV lAURA STOICA'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>CIRCUS IV </span> - lAURA STOICA</p>
                        </div>
                        <div className={'w-full flex flex-col gap-2 p-0 lg:p-8 lg:hidden'}>
                            <img src={'/assets/estul_12.jpg'} className={'w-full'} alt={'CIRCUS V lAURA STOICA'} />
                            <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>CIRCUS V </span> - lAURA STOICA</p>
                        </div>
                    </div>
                </div>

                <div className={'col-span-full lg:col-span-1 lg:border-r lg:border-r-primary pt-16 flex flex-col gap-8'}>
                    <div className={'w-full flex flex-col items-end justify-end text-end pr-4 py-4 border-b border-b-primary border-r border-r-primary pt-8 lg:border-r-transparent'}>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Ioana</p>
                        <p className={'text-3xl lg:text-5xl uppercase font-semibold'}>Mihai</p>
                    </div>
                    <div className={'lg:py-8 lg:pr-8 w-full flex flex-col gap-8'}>
                        <img src={'/assets/estul_13.jpg'} className={'w-full -ml-8 lg:hidden'} />
                        <p className={'w-full text-justify text-md lg:text-lg leading-6 font-light'}>
                            Dintr-un punct de vedere diferit, Ioana Mihai vorbește în numele laturii feminine din spațiul românesc și înfățișează printr-o instalație problemele pe care această latură le întâmpină. Ea pune în discuție gradul redus de accesibilitate la produsele de igiena feminină și face o incursiune în cazul acestei probleme începând prin a analiza situația dată la nivel internațional din trecut până în prezent, urmărind ulterior parcursul românesc și impasurile sale. Instalația este constituită din procese de cercetare și analiză, fără să propună o soluționare a problemelor identificate. Lucrarea marchează punctul de plecare pentru o documentare de lungă durată, fiind începutul unui studui de caz și a unui proiect vizual amplu
                        </p>
                    </div>
                </div>

                <div className={'col-span-full hidden lg:flex lg:flex-col gap-8 lg:col-span-1 pt-16 '}>
                    <div className={'w-full flex flex-col gap-2 p-0 lg:p-4'}>
                        <img src={'/assets/estul_13.jpg'} className={'w-full'} alt={'2 DIN 10 IOANA MIHAI'} />
                        <p className={'w-full uppercase text-sm'}><span className={'font-bold'}>2 DIN 10</span> - IOANA MIHAI</p>
                    </div>
                </div>

                <div className={'col-span-1 hidden lg:flex border-b border-r border-r-primary border-b-primary p-20'}>
                </div>
                <div className={'col-span-1 hidden lg:flex border-b border-b-primary p-20'}>
                </div>

                <p className={'col-span-full mt-8 pt-4 text-justify text-md lg:text-lg leading-6 font-light border-t border-t-primary lg:border-t-transparent'}>
                    Elementele care propun o imagine de ansamblu în ceea ce privește panorama din Estul Sălbatic
                    prin ochii artiștilor vizuali Radu-Mihai Tănase, Ruxandra Mărgineanu, Ioana Mihai, Alexandra Tîrpescu
                    și Laura Stoica sunt aparent eterogene. Ele își concretizează echilibrul prin scopul comun de a suprinde
                    laturi ale societății românești prin propriile experiențe. Astfel se poate asambla un portret construit după anii 2000,
                    ce include o parte intimă a copilăriei împreună cu cea a obiectelor comune, a locurilor prin care au crescut și s-au format,
                    a relațiilor construite cu persoanele din jurul nostru precum și analizele pe care le-au făcut asupra propriei societăți.
                </p>
            </div>

        </div>
    )
}
